import { createStore } from 'vuex'

import trips from './trips'
import tripGroups from './tripGroups'
import general from './general'

export default createStore({
  modules: {
    trips,
    tripGroups,
    general
  }
})
