export default class UsersModel {
  constructor (data) {
    this.id = ''
    this.firstNames = ''
    this.role = null
    this.tripGroups = []

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        firstNames: data.UserName,
        role: data.Role,
        tripGroups: data.TripGroups
      })
    }
  }
}
