export default class InfoModel {
  constructor (data) {
    this.id = ''
    this.title = ''
    this.subTitle = ''
    this.description = ''
    this.image = ''
    this.childrenInfo = []
    this.parentId = ''
    this.url = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        title: data.Title,
        subTitle: data.SubTitle,
        description: data.Description,
        image: data.Image,
        url: data.Url,
        childrenInfo: data.ChildrenInfo?.map(info => new InfoModel(info)),
        parentId: data.ParentId
      })
    }
  }
}
