import { SET_TIMEZONES, SET_USERS, SET_LEGAL_DOC, SET_DOC_VALUE, SET_USER, SET_ROLES, SET_PROFILE, SET_PARTICIPANT, SET_PARTICIPANTS } from './types'
import TimezoneModel from '@/enteties/Timezone'
import UsersModel from '@/enteties/Users'
import LegalDocModel from '@/enteties/LegalDoc'
import RolesModel from '@/enteties/Roles'
import ProfileModel from '@/enteties/Profile'
import ParticipantModel from '@/enteties/Participants'

export default {
  [SET_TIMEZONES] (state, payload) {
    state.timezones = payload.map(timezone => new TimezoneModel(timezone))
  },
  [SET_USERS] (state, payload) {
    state.users = payload.Users.map(user => new UsersModel(user))
    state.totalUsers = payload.Total
  },
  [SET_USER] (state, payload) {
    state.user = new UsersModel(payload)
  },
  [SET_LEGAL_DOC] (state, payload) {
    state.legalDoc = new LegalDocModel(payload)
  },
  [SET_DOC_VALUE] (state, payload) {
    state.legalDoc[payload.field] = payload.value
  },
  [SET_ROLES] (state, payload) {
    state.roles = payload.map(role => new RolesModel(role))
  },
  [SET_PROFILE] (state, payload) {
    state.profile = new ProfileModel(payload)
  },
  [SET_PARTICIPANT] (state, payload) {
    state.participant = payload
  },
  [SET_PARTICIPANTS] (state, payload) {
    state.participants = payload.Itens.map(participant => new ParticipantModel(participant))
    state.participantsTotal = payload.Total
  }
}
