import SessionModel from '@/enteties/Session'

export default class DayModel {
  constructor (data) {
    this.id = ''
    this.name = ''
    this.day = ''
    this.sessions = []

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        name: data.Name,
        day: data.Day,
        sessions: data.Sessions.map(session => new SessionModel(session))
      })
    }
  }
}
