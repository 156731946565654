import axios from 'axios'
import Cookies from 'js-cookie'
import router from '../router'
import Loading from 'quasar/src/plugins/Loading.js';

const LOGIN_URL = '/connect/token'

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(config => {
  Loading.show()
  const token = Cookies.get('TAAuthorization')

  if (token && config.url !== LOGIN_URL) {
    config.headers.Authorization = `Bearer ${token}`
    axios.defaults.withCredentials = true
  }

  return config
})

axios.interceptors.response.use(
  response => {
    if (response.data.access_token) {
      Cookies.set('TAAuthorization', response.data.access_token)
    }
    Loading.hide()
    return response
  },
  error => {
    if (error.response?.status === 401) {
      Cookies.remove('GAuthorization')
      router.push({ name: 'login' })
    }
    Loading.hide()
    throw error
  }
)
