import RolesModel from '@/enteties/Roles'
import UsersModel from '@/enteties/Users'
import ProfileModel from '@/enteties/Profile'
import ParticipantModel from '@/enteties/Participants'

export default {
  timezones: [],
  users: [],
  totalUsers: 0,
  legalDoc: [],
  user: new UsersModel(),
  roles: new RolesModel(),
  profile: new ProfileModel(),
  participant: new ParticipantModel(),
  participants: [],
  participantsTotal: 0
}
